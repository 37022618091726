<template>
	<div class="documents-dashboard">
		<DocumentsHeader
			v-if="marks"
			:activeRole="activeRoleName"
			:marks="marks"
			@changeMark="changeMarkChecked"
			@uncheckAllMarks="uncheckAllMarks"
		/>
		<Preloader :loading="isLoading" />

		<template v-if="!isLoading">
			<template v-if="categories && categories.length">
				<Columns :columns="$mq | mq({ xs: 1, sm: 1, md: 2, lg: 3, xl: 4 })">
					<template
						v-for="(item, index) in categories"
					>
						<DndCard
							v-if="(item.knowledges && item.knowledges.length) || ((item.subcategories && item.subcategories.length) && item.subcategories.some(el => el.knowledges.length))"
							:key="index"
							:item="item"
							:index="index"
							:user-can-edit="userCanEdit"
							@deleteCategory="deleteCategory"
							@editPinState="editPinState"
							@updateCategoryName="updateCategoryName"
							@deleteItem="deleteItem"
							@onDrop="onDrop"
							@onSubcategoryDrop="onSubcategoryDrop"
							@deleteSubcategoryItem="deleteSubcategoryItem"
							@deleteSubcategory="deleteSubcategory"
						/>
					</template>
				</Columns>
			</template>
			<EmptyBackplate
				v-else
				to="/documents/create"
				message="Еще не добавлено ни одного знания"
			/>
		</template>
	</div>
</template>

<script>
// import modules
import KnowledgeModule from '@/store/modules/knowledge/KnowledgeModule';
import TagsModule      from '@/store/modules/tags/TagsModule';

// import usable functional
import Notify      from '@/services/helpers/notify';
import ApiClient   from '@/api/api.client';
import UsersModule from '@/store/modules/users/UsersModule';

// import components
import DocumentsHeader          from '@/components/documents/documentsDashboardHeader';
import DndCard                  from '@/components/documents/dashboard-item/dndCard.vue';
import EmptyBackplate           from '@/components/app-bar/EmptyBackplate.vue';
import Preloader                from '@/components/preloader/PreloaderWrap.vue';
import { Columns }              from 'vue-columns';

export default {
	name: 'DocumentsDashboardRole',

	data()
	{
		return {
			categories    : null,
			marks         : null,
			isLoading     : true,
			activeRoleName: null,
		}
	},

	components: {
		Columns,
		DocumentsHeader,
		EmptyBackplate,
		Preloader,
		DndCard,
	},

	computed:
	{
		userCanEdit() { return UsersModule.currentUser.admin || UsersModule.currentUser.permitions.knowledge.manage; },
	},

	methods:
	{
		changeMarkChecked(markId)
		{
			const idx = this.marks.findIndex(el => el.id === markId);
			this.marks[idx].checked = !this.marks[idx].checked;

			// fetching knowledges with filter
			clearInterval(this.filterTimeout);
			this.filterTimeout = setTimeout(() => {
				let filter = '';

				this.marks.forEach((el, i) => {
					if (el.checked)
						filter = filter + `&filter[tags][${i}]=` + el.id;
				});

				this.fetchRole(filter);
			}, 1000);
		},

		uncheckAllMarks()
		{
			this.marks.forEach(el => el.checked = false);
			this.fetchRole();
		},

		async fetchRole(filter = '')
		{
			this.isLoading = true;

			await KnowledgeModule.fetchKnowledges(`?filter[role]=${this.$route.params.id}${filter}`)
				.then((res) => {
					if (this.$route.params.id === '0')
					{
						this.categories = res.data.data.for_all.categories;
						this.activeRoleName = 'Для всех';
						this.isLoading = false;
						return;
					}
					this.categories  = res.data.data[this.$route.params.id].items.categories;
					this.activeRoleName = res.data.data[this.$route.params.id].name;
					this.isLoading = false;
				})
				.catch(() => {
					Notify.error('Что-то пошло не так, попробуйте позже.');
				})
		},

		async fetchMarks()
		{
			await TagsModule.fetchTags()
				.then(() => {
					this.marks = TagsModule.tags.map((el) => {
						return {
							id: el.id,
							title: el.name,
							color: el.color,
							checked: false,
						};
					});
				});
		},

		// drag and drop

		getChildPayload (groupIndex, itemIndex)
		{
			return this.categories[groupIndex].knowledges[itemIndex];
		},

		onDrop({ groupIndex, value })
		{
			const { removedIndex, addedIndex, payload } = value;

			if (removedIndex === null && addedIndex === null)
				return;

			if (removedIndex !== null)
				this.categories[groupIndex].knowledges.splice(removedIndex, 1);

			if (addedIndex !== null)
			{
				this.categories[groupIndex].knowledges.splice(addedIndex, 0, payload);
				const categoryId = this.categories[groupIndex].id;
				const knowledgePayload = {
					id: payload.id,
					category_id: categoryId,
					subcategory_id: '0',
				}
				ApiClient.knowledge.updateKnowledge(knowledgePayload);
			}
		},

		onSubcategoryDrop({ groupIndex, subcategoryIndex, dropResult })
		{
			const { removedIndex, addedIndex, payload } = dropResult;

			if (removedIndex === null && addedIndex === null)
				return;

			if (removedIndex !== null)
				this.categories[groupIndex].subcategories[subcategoryIndex].knowledges.splice(removedIndex, 1);

			if (addedIndex !== null)
			{
				this.categories[groupIndex].subcategories[subcategoryIndex].knowledges.splice(addedIndex, 0, payload);
				const categoryId = this.categories[groupIndex].id;
				const subcategoryId = this.categories[groupIndex].subcategories[subcategoryIndex].id;

				const knowledgePayload = {
					id: payload.id,
					category_id: categoryId,
					subcategory_id: subcategoryId,
				}
				ApiClient.knowledge.updateKnowledge(knowledgePayload);
			}
		},

		// item functions

		deleteCategory(value)
		{
			const idx = this.categories.findIndex(el => el.id === value);

			ApiClient.categories.deleteCategory(value)
				.then(() => {
					this.categories.splice(idx, 1);
					Notify.success('Категория удалена');
				})
				.catch(() => Notify.error('Что-то пошло не так, попробуйте позже.'));
		},

		deleteItem({ categoryId, itemId })
		{
			const categoryIdx = this.categories.findIndex(el => el.id === categoryId);
			const knowledgeIdx = this.categories[categoryIdx].knowledges.findIndex(el => el.id === itemId);

			ApiClient.knowledge.deleteKnowledge(itemId).then(() => {
				Notify.success('Знание удалено');
				this.categories[categoryIdx].knowledges.splice(knowledgeIdx, 1);
			});
		},

		deleteSubcategory({ groupIndex, subcategoryIndex })
		{
			const id = this.categories[groupIndex].subcategories[subcategoryIndex].id;
			ApiClient.subCategories.deleteSubcategory(id).then(() => {
				Notify.success('Подкатегория удалена');
				this.categories[groupIndex].subcategories.splice(subcategoryIndex, 1);
			});
		},

		deleteSubcategoryItem({ groupIndex, subcategoryIndex, event })
		{
			const subcategoryIdx = this.categories[groupIndex].subcategories.findIndex(el => el.id === subcategoryIndex);
			const subcategoryItemIdx = this.categories[groupIndex].subcategories[subcategoryIdx].knowledges.findIndex(el => el.id === event);

			ApiClient.knowledge.deleteKnowledge(event).then(() => {
				Notify.success('Знание удалено');
				this.categories[groupIndex].subcategories[subcategoryIdx].knowledges.splice(subcategoryItemIdx, 1);
			});
		},

		editPinState({ id, state })
		{
			const idx = this.categories.findIndex(el => el.id === id);

			ApiClient.categories.updateCategory(id, { pinned: state })
				.then(() => {
					this.categories[idx].pinned = state;
					Notify.success(`Категория ${state ? 'закреплена' : 'откреплена'}`);
				});
		},

		updateCategoryName({ id, value })
		{
			const idx = this.categories.findIndex(el => el.id === id);

			ApiClient.categories.updateCategory(id, { name: value })
				.then(() => {
					this.categories[idx].name = value;
					Notify.success('Категория изменена');
				})
				.catch(() => Notify.success('Название категории должно быть уникальным'));
		},
	},

	async mounted()
	{
		await this.fetchRole();
		await this.fetchMarks();
	},
}
</script>

<style lang="scss" scoped>
	.documents-dashboard
	{
		padding: 30px 22px;
		max-width: 1440px;
		width: calc(100% - 68px);
		margin: 0 auto;
		padding-bottom: 100px;
	}

	.documents-dashboard__item
	{
		position: relative;
		border-radius: 19px;
		margin: 0 20px 30px 0;
		background: #222222;

		&::before
		{
			content: '';
			position: absolute;
			z-index: -1;
			top: -10px;
			width: 98%;
			border-radius: 19px;
			height: 50px;
			background: #2C2C2C99;
			left: 50%;
			transform: translateX(-50%);
		}

		.documents-dashboard__item-subcategories-wr:last-of-type
		{
			padding-bottom: 20px;
		}
	}

	.documents-dashboard__item-subcategories-wr { padding: 30px 30px 0 30px; }
</style>